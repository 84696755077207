import React from "react";
import { Link } from "react-router-dom";

import styles from './Footer.module.scss';
import logo from '../../assets/logo/KoreanLogo-reversal.png';
import { useMediaQuery } from "react-responsive";

export const Footer = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 980px)' });

	return (
		<>
			{isMobile ? (
				<footer className={styles.footer}>

					<div className={styles.logoBox}>
						<Link to='/'>
							<img className={styles.logo} src={logo} alt="posco 평택 푸르지오 센터파인" />
						</Link>
						<div className={styles.text2}>상담문의 1811-1726</div>
					</div>

					<div className={styles.menu} >
						<Link to='/' className={styles.link}>
							<div>HOME</div>
						</Link>
						<Link to='/BusinessGuide' className={styles.link}>
							<div>사업안내</div>
						</Link>
						<Link to='/LocationEnvironment' className={styles.link}>
							<div>입지환경</div>
						</Link>
						<Link to='/Premium' className={styles.link}>
							<div>프리미엄</div>
						</Link>
						<Link to='/GuideComplex' className={styles.link}>
							<div>단지안내</div>
						</Link>
						<Link to='/EquilibriumGuide' className={styles.link}>
							<div>평형안내</div>
						</Link>
					</div>

					<div className={styles.text}>※ 홈페이지에 사용된 이미지 컷은 소비자의 이해를 돕기 위한 것으로 사업과정 및 실제 시공에 따라 변경될 수 있습니다</div>
					<div className={styles.text}>Copyright © 2024 평택 푸르지오 센터파인 All rights reserved.</div>
				</footer>
			) : (
				<footer className={styles.footer}>

					<Link to='/'>
						<img className={styles.logo} src={logo} alt="posco 평택 푸르지오 센터파인" />
					</Link>

					<div className={styles.menu} >

						<Link to='/' className={styles.link}>
							<div>HOME</div>
						</Link>
						<Link to='/BusinessGuide' className={styles.link}>
							<div>사업안내</div>
						</Link>
						<Link to='/LocationEnvironment' className={styles.link}>
							<div>입지환경</div>
						</Link>
						<Link to='/Premium' className={styles.link}>
							<div>프리미엄</div>
						</Link>
						<Link to='/GuideComplex' className={styles.link}>
							<div>단지안내</div>
						</Link>
						<Link to='/EquilibriumGuide' className={styles.link}>
							<div>평형안내</div>
						</Link>
					</div>

					<div className={styles.text}>※ 홈페이지에 사용된 이미지 컷은 소비자의 이해를 돕기 위한 것으로 사업과정 및 실제 시공에 따라 변경될 수 있습니다</div>
					<div className={styles.text}>Copyright © 2024 평택 푸르지오 센터파인 All rights reserved.</div>
				</footer>
			)}
		</>

	)
}