import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styles from './IpBox.module.scss'
import { useRecoilState } from "recoil";
import { ipState, isIpBoxState } from "../../recoil/atom";
import { IoCloseOutline } from "react-icons/io5";

const date = new Date();
const formattedDate = date.toISOString().replace('T', ' ').replace(/\.\d+/, '');

export const IpBox = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 980px)' });
  const [ip, setIp] = useRecoilState(ipState);
  const [isIpBox, setIsIpBox] = useRecoilState(isIpBoxState);

  return (
    <>
      {isMobile ? (
        isIpBox ? <MiniVersion ipData={ip} isOpen={() => setIsIpBox(!isIpBox)}/> : <LargeVersion ipData={ip} isOpen={() => setIsIpBox(!isIpBox)}                                />
      ) : (
        !isIpBox ? <MiniVersion ipData={ip} isOpen={() => setIsIpBox(!isIpBox)}/> : <LargeVersion ipData={ip} isOpen={() => setIsIpBox(!isIpBox)}/>
      )}
    </>
  )
}

const MiniVersion = ({ipData, isOpen}) => {
  return (
    <div className={styles.closedBox} onClick={() => isOpen()}>
      {ipData.user} +{ipData.accessTotal}
    </div>
  )
}

const LargeVersion = ({ipData, isOpen}) => {
  return (
    <div className={styles.container} onClick={() => isOpen()}>

      <div className={styles.tilte}>총 접속 횟수 : {ipData.accessTotal}</div>

      <div className={styles.ipData}>
        <div className={styles.content}>
          많은 접속으로 인해 광고비용이 지출되오니<br />
          너무 과한 접속을 자제부탁드리고<br />
          즐겨찾기 등록 후 접속해주시면 감사하겠습니다
        </div>

        <div className={styles.visitTitle}>&lt; 방문 데이터 &gt;</div>

        <table>
          <thead>
            <tr>
              <th>IP 주소</th>
              <th>방문 일시</th>
              <th>오늘 접속 횟수</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ipData.user}</td>
              <td>{formattedDate}</td>
              <td>{ipData.access && ipData.access.length > 0 ? ipData.access[ipData.access.length - 1].accessNumber : 0}</td>
            </tr>
          </tbody>
        </table>

      </div>

      <IoCloseOutline
        onClick={() => isOpen()}
        className={styles.closeButton}
        color="#FFFFFF"
        size={25}
      />
    </div>
  )
}